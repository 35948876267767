import React from 'react';
import AmmiakUstanovkiHtml from './ammiakustanovkihtml'


const AmmiakUstanovki = () => {
    return(
        <AmmiakUstanovkiHtml />
    )
}

export default AmmiakUstanovki