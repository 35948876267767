import React from "react"
import Seo from "../../components/seo"

import AmmiachnyeComponent from "../../components/BlogPages/AmmiakUstanovki"


const AmmiakUstanovki = () => (

  <>
    <Seo 
      title="Аммиачные холодильные системы и установки"
      description="Производим современные аммиачные холодильные установки и проектируем технологичные холодильные системы на аммиаке."
      keywords="аммиак, холодильные, системы, установки, фреон, CO2"
    />
    <AmmiachnyeComponent />
  </>
  
)

export default AmmiakUstanovki